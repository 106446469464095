<template>
  <v-card>
    <v-card-title>
      <h1 class="text-h5">ログイン</h1>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
            prepend-icon="mdi-account-circle"
            required
            label="メールアドレス"
            v-model="email"
        />
        <v-text-field
            prepend-icon="mdi-lock"
            label="パスワード"
            v-model="password"
            v-bind:type="showPassword ? 'text' : 'password'"
            v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off' "
            @click:append="showPassword = !showPassword"
        />
        <v-card-actions class="justify-center">
          <v-btn color="info" @click="userSignIn">ログイン</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

import firebase from "../../plugins/firebase"

export default {
  name: "LoginCard",
  data() {
    return {
      showPassword: false,
      email: '',
      password: '',
    }
  },
  methods: {
    userSignIn() {
      firebase.login(this.email,this.password);
    },
  },
  computed: {

  }
}
</script>

<style scoped>

</style>