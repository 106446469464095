<template>
    <v-container fluid fill-height>
      <v-row justify="center" align="center" wrap>
        <v-col cols="12" class='login-form'>
          <LoginCard></LoginCard>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

import LoginCard from "../../molecules/LoginCard";

export default {
  name: "Login",
  components: {
    LoginCard,
  },
}
</script>

<style scoped>
.login-form {
  max-width: 500px
}
</style>